import { useEffect, useState } from 'react';
import { Button, Box, Container, Typography, List, ListItem, ListItemText, Grid, Skeleton } from '@mui/material'
import { BACKEND_ADDRESS } from '../App';
import { Preformatted } from '../components/base/typography';
import EmployeeBadge from '../components/persona/EmployeeBadge';
import { useLocalStorage } from "@uidotdev/usehooks";
import { Title } from "../components/base/typography"
import { Pages } from '../Types';


export default function Background() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const [loadingEmployee, setLoadingEmployee] = useState(true);
    const [loadingCompany, setLoadingCompany] = useState(true);

    useEffect(() => {
        if (loadingEmployee) {
            fetch(BACKEND_ADDRESS + "/persona", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ '_id': participant._id })
            })
                .then(async (res) => {
                    let result = await res.json();
                    setEmployee(result);
                    setLoadingEmployee(false);
                })
                .catch((err) => console.log(err));
        }

        if (loadingCompany) {
            fetch(BACKEND_ADDRESS + "/company", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ '_id': participant._id })
            })
                .then(async (res) => {
                    let result = await res.json();
                    setCompany(result);
                    setLoadingCompany(false);
                })
                .catch((err) => console.log(err));
        }

    }, []);


    const handleClickConfirm = () => {
        setExperiment({ ...experiment, page: Pages.MAILBOX_DEMO })
    };

    const handleProfilePicture = (picture) => {
        setEmployee({ ...employee, picture: picture });
    }

    return (
        <Container>
            <Grid container spacing={4}>

                <Grid item xs={8}>
                    <Title>
                        設定
                    </Title>
                    {loadingEmployee || loadingCompany ? (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    ) : (
                        <>
                            <Typography>
                                この調査では、あなたは<b>{company.name}</b>の社員、<b>{employee.surname}{employee.name}</b>としてロールプレイして頂きます。
                            </Typography>
                            <Typography>
                                こちらがあなたの社員証になります。キャラクターの外見を変えることもできます。
                            </Typography>
                            <Typography paddingBottom={4}>
                                {employee.surname}{employee.name}の担当業務は<b>{employee.responsibilities[0]}そして{employee.responsibilities[1]}</b>です。
                            </Typography>
                            <Typography>
                                {employee.surname}{employee.name}の勤め先である{company.name}が使用しているツールやEメールアドレスは以下のとおりです。
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText>・ {company.name}のウェブサイトは次の二種類です：<Preformatted>{company.domains[0]}</Preformatted>、<Preformatted>{company.domains[1]}</Preformatted></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>・ あなたの業務用Eメールアドレスは<Preformatted>{employee.email}</Preformatted>です。</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>・ 業務で用いられるEメールアドレスは次のようなフォーマットをとっています：<Preformatted>名.姓@{company.domains[0]}</Preformatted>。
                                        部署ごとのEメールアドレスも存在します。例えば、人事部（Human Resources）は<Preformatted>hr@{company.domains[0]}</Preformatted>を使用します。</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>・ {company.name}はGoogle Drive (<Preformatted>drive.google.com</Preformatted>)、Sharepoint (<Preformatted>sharepoint.com</Preformatted>)、そしてMicrosoft Teams (<Preformatted>teams.microsoft.com</Preformatted>)を使用してドキュメントの共有やメッセージのやりとりを行います。</ListItemText>
                                </ListItem>
                            </List>

                            <Typography>
                                <b>あなたのタスクは{employee.surname}{employee.name}宛てに届いたEメールを処理することです。</b>この際{company.name}で用いられているEメールソフトウェアを使用していただきます。制限時間以内にできるだけ多くのEメールを処理していただきます。
                                これから、どのように処理するのかチュートリアルを通して説明しますので、よく目を通してください。
                            </Typography>

                            <Button variant="contained" className="Button" onClick={handleClickConfirm} sx={{ marginTop: '2rem' }}>
                                次へ進む
                            </Button>
                        </>
                    )
                    }
                </Grid>

                <Grid item xs={4}>
                    {loadingEmployee || loadingCompany ? (
                        <Skeleton variant="circular" width={200} height={200} />
                    ) : (
                        <EmployeeBadge
                            employee={employee}
                            company={company}
                            pictureSelector={true}
                            pictureSelectorHandler={handleProfilePicture}
                        />
                    )
                    }
                </Grid>

            </Grid>
        </Container>
    );
}
