import TargetURL from "../elements/TargetURL";
import { useRef, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import LoggerDialog from "../../dialogs/LoggerDialog";
import { Preformatted } from '../../base/typography';


export default function TypeDomain({challenge, handler, ...props}) {
    
    const valueRef = useRef(null);

    const handleChange = (event) => {
        const value = valueRef.current.value;
        // set to lowercase when passing to handler
        handler(value.toLowerCase());
    }

    const preventDefault = (event) => {
        event.preventDefault();
    }

    const [domainExplanationDialogOpen, setdomainExplanationDialogOpen] = useState(false);

    const domainDescription = <>
        <Typography>
            リンクの最後に表示されるウェブサイトを特定する部分を<emph>ドメイン</emph>と呼びます。
        </Typography>
        <Typography>
            例えば、<Preformatted>https://www.foo.example.com/page</Preformatted>の場合、ドメイン名は<Preformatted>example.com</Preformatted>となります。
        </Typography>
    </>
    
    return (
        <Box>
        
            <Typography paddingBottom={2}>あなたは次のリンクをクリックしました。</Typography>
            <TargetURL url={challenge.url} />

            <Typography paddingTop={2}>アクセスを試みたリンクのドメイン名を<b>再度入力してください</b>。</Typography>
            <Typography paddingBottom={2} sx={{fontSize: "0.8rem"}}>(<Button variant='text' onClick={(event) => setdomainExplanationDialogOpen(true)}>ドメイン名とは？</Button>)</Typography>
            
            <TextField 
                className="attention-tutorial-challenge" 
                fullWidth 
                placeholder='ドメイン名' 
                variant='outlined' 
                inputRef={valueRef} 
                onChange={handleChange}
                onCut={preventDefault}
                onCopy={preventDefault}
                onPaste={preventDefault}
                onDrop={preventDefault}
            />

            <LoggerDialog
                open={domainExplanationDialogOpen}
                onClose={() => setdomainExplanationDialogOpen(false)}
                title="ドメイン名とは？"
                description={domainDescription}
                eventName="domain_description_type"
                cancelText="Ok" />

        </Box>
        
    );
}
