import {useState} from 'react';
import {Button, Box, Typography, Container, Paper} from '@mui/material'
import FormControl from '@mui/material/FormControl';
import { BACKEND_ADDRESS } from '../App';
import { Subtitle, Title } from '../components/base/typography';
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import QuestionnaireLikert from '../components/framework/Questionnaire/QuestionnaireLikert';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Pages } from '../Types';


let commonQs = [
    ["detecting_spam_by_reading", "Eメールを読み詐欺メールだと特定するのは容易だと感じた。"],
    ["scam_difficult_to_detect", "詐欺メールの特定が難しいと感じた。"],
    ["recognize_legitimate_urls", "正当なリンクを特定するのは容易だと感じた。"],
    ["scam_spotting_difficulty", "Eメールに含まれる不正なリンクを特定するのが難しいと感じた。"]
];

let passiveQs = [
    ["re-reading_useful", "クリックしたリンクを確認ページにおいて読み直すのは効果的だと感じた。"],
    ["ignored_urls", "確認ページで表示されたリンクは無視した。"],
    ["changed_mind_at_confirmation", "確認ページで表示されたリンクが判断の手助けになった。"]
];

let reorderQs = [
    ["re-reading_useful", "クリックしたリンクを確認ページにおいて組み直すのは効果的だと感じた。"],
    ["ignored_urls", "確認ページでリンクを組み直している最中、表示されたリンクには目を通さなかった。"],
    ["changed_mind_at_confirmation", "確認ページでリンクを組み直していたことが判断の手助けになった。"]
];

let mechanismQs = [
    ["help_spot_phishing", "不正なリンクを特定するのにセキュリティツールが役に立った。"],
    ["spot_phishing_without_tool", "不正なリンクを特定するのにセキュリティツールは不要だった。"],
    ["clearly_highlighted_mistakes", "間違ったリンクの特定を正すのにセキュリティツールは役に立った。"],
    ["tool_in_the_way", "タスクをこなすのにセキュリティツールは邪魔だった。"],
    ["tutorial_clear", "セキュリティツールのチュートリアルはわかりやすかった。"],
    ["tutorial_sufficient_information", "セキュリティツールのチュートリアルを通してタスクをこなすのに十分な情報が得られた。"],
    ["coloring_url_useful", "リンクの様々な部分を色分けしているのは役に立った。"],
    ["wish_reading_url_simpler", "リンクがもう少し読みやすければ良いと感じた。"],
    ["tool_presentation_confusing", "セキュリティツールが複雑だと感じた。"],
]

let specificQs_list = [
    ["click_domain_challenge_useful", "ドメイン名を一覧から選択するセキュリティツールは役に立った。"],
    ["click_domain_challenge_frustrating", "ドメイン名を一覧から選択するセキュリティツールは煩わしいと感じた。"],
    ["click_domain_challenge_difficult", "ドメイン名を一覧から選択するセキュリティツールは難しいと感じた。"]
]
let specificQs_highlight = [
    ["click_domain_challenge_useful", "ドメイン名をハイライトするセキュリティツールは役に立った。"],
    ["click_domain_challenge_frustrating", "ドメイン名をハイライトするセキュリティツールは煩わしいと感じた。"],
    ["click_domain_challenge_difficult", "ドメイン名をハイライトするセキュリティツールは難しいと感じた。"]
]
let specificQs_type = [
    ["click_domain_challenge_useful", "ドメイン名を再度入力するセキュリティツールは役に立った。"],
    ["click_domain_challenge_frustrating", "ドメイン名を再度入力するセキュリティツールは煩わしいと感じた。"],
    ["click_domain_challenge_difficult", "ドメイン名を再度入力するセキュリティツールは難しいと感じた。"]
]


export default function PostQ() {

    const [feedbackFormValues, setFeedbackFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleChange = (event) => {
        
        setFeedbackFormValues({
            ...feedbackFormValues,
            [event.target.name]: event.target.value,
          });
          
    };

    const handleClickConfirm = () => {
        
        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'feedback',
                    'form_data': feedbackFormValues
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.REWARD })
            })
            .catch((err) => console.log(err));
  
        
    };

    function renderQs(questionsList) {

        return (
            <QuestionnaireGroup least="全く同意しない" most="強く同意する">
                {
                    questionsList.map((items, idx) => (
                        <QuestionnaireLikert key={idx} label={items[1]} name={items[0]} onChange={handleChange} />
                    ))
                }
            </QuestionnaireGroup>
        )
    }

    return (
        <Container>
            <Title>
                Post-Experiment Questionnaire
            </Title>

        <Typography>
            本調査について、最後にアンケートを取ります。以下の質問に回答をお願いします。
        </Typography>
        <Typography pb={4} pt={2}>
            このアンケートでは、1（「まったくそう思わない」）から5（「非常にそう思う」）にかけての5段階の数字を選んでください。
        </Typography>

        <FormControl>
            {renderQs(commonQs)}
        </FormControl>

        {participant.group === "baseline" &&
            <>
                <Subtitle>リンク確認ページ</Subtitle>
                <Typography>
                    以下は、<b>クリックしたリンクが再度表示され、確認が行われたページ</b>に関する質問になっております。このページの内容をお忘れの場合は下記の画像をご確認ください。
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/baseline.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(passiveQs)}
                </FormControl>
            </>
        }

        {participant.group === "reorder" &&
            <>
                <Subtitle>リンク組み直しセキュリティツール</Subtitle>
                <Typography>
                    以下は、<b>クリックしたリンクを再度組み直すセキュリティツールを表示したページ</b>に関する質問になっております。このページの内容をお忘れの場合は下記の画像をご確認ください。
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/reorder.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(reorderQs)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.length &&
            <>
                <Subtitle>リンクセキュリティツール</Subtitle>
                <Typography>
                    クリックしたリンクに施されたセキュリティ機構について伺います。
                </Typography>
                <FormControl>
                    {renderQs(mechanismQs)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("1") &&
            <>
                <Subtitle>リンクの一覧から選択するセキュリティツール</Subtitle>
                <Typography mb={4}>
                    以下は、<b>クリックしたリンクを一覧から選択するセキュリティツール</b>に関する質問になっております。このページの内容をお忘れの場合は下記の画像をご確認ください。
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/list.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(specificQs_list)}
                </FormControl></>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("2") &&
            <>
                <Subtitle>リンクの一部をハイライトするセキュリティツール</Subtitle>
                <Typography mb={4}>
                    以下は、<b>クリックしたリンクの一部をハイライトし選択するセキュリティツール</b>に関する質問になっております。このページの内容をお忘れの場合は下記の画像をご確認ください。
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/highlight.gif' width="75%" style={{margin: "1rem auto"}}/>
                    </Paper>
                    {renderQs(specificQs_highlight)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("3") &&
            <>
                <Subtitle>リンクを再入力するセキュリティツール</Subtitle>
                <Typography mb={4}>
                    以下は、<b>クリックしたリンクを再入力するセキュリティツール</b>に関する質問になっております。このページの内容をお忘れの場合は下記の画像をご確認ください。
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/type.gif' width="75%" style={{margin: "1rem auto"}}/>
                    </Paper>
                    {renderQs(specificQs_type)}
                </FormControl>
            </>
        }

        <Box py={2}></Box> 
        <Button variant="contained" className="Button" onClick={(event) => handleClickConfirm()}>
            次へ進む
        </Button>
        <p>&nbsp;</p>
        
        </Container>
    );
}
