import { Typography, Box } from '@mui/material';
import { useState, useEffect, useRef } from 'react';

export default function Timer({ minutes, onTimeout, display = true, paused = false, ...props }) {
	const [seconds, setSeconds] = useState(() => {
		const savedSeconds = localStorage.getItem('timerSeconds');
		return savedSeconds ? Number(savedSeconds) : minutes * 60;
	});
	const [isActive, setIsActive] = useState(true);
	const countRef = useRef(null);

	useEffect(() => {
		startTimer();
		return () => clearInterval(countRef.current); // Clear interval on unmount
	}, []);

	useEffect(() => {
		if (isActive) {
			countRef.current = setInterval(() => {
				setSeconds((seconds) => seconds - 1);
			}, 1000);
		} else {
			clearInterval(countRef.current);
		}
		return () => clearInterval(countRef.current); // Clear interval when isActive changes
	}, [isActive]);

	useEffect(() => {
		if (paused) {
			pauseTimer();
		} else {
			startTimer();
		}
	}, [paused]);

	useEffect(() => {
		if (seconds === 0) {
			stopTimer();
			onTimeout();
		}
		localStorage.setItem('timerSeconds', seconds); // Save seconds to localStorage
	}, [seconds]);

	function startTimer() {
		setIsActive(true);
	}

	function pauseTimer() {
		setIsActive(false);
	}

	function stopTimer() {
		setIsActive(false);
		setSeconds(minutes * 60);
	}

	return (
		<Box display={display ? 'block' : 'none'} {...props}>
			<Typography>残り時間</Typography>
			<Typography>
				<b>
					{Math.floor(seconds / 60)}:{seconds % 60 < 10 ? '0' : ''}
					{seconds % 60}
				</b>
			</Typography>
		</Box>
	);
}
