import {Button, Box, Typography, Container} from '@mui/material'
import { useLocalStorage } from '@uidotdev/usehooks';
import { Subtitle, Title } from '../components/base/typography';
import { ExperimentProgress, Pages, baseExperimentStatus } from '../Types';

export default function Debriefing() {

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    const handleClickConfirm = () => {
        setExperiment({ ...experiment, page: Pages.POST_QUESTIONNAIRE })
    };

    return (
        <Container>

            <Title>
                調査にご協力いただきありがとうございました。
            </Title>

            <Subtitle>
                {experiment.status === ExperimentProgress.OVER && "調査は完了しました。"}
                {experiment.status === ExperimentProgress.LEFT_EARLY && "調査が中断されました。"}
                {experiment.status === ExperimentProgress.TIMEOUT && "残念ながら時間切れになりました。"}
            </Subtitle>

            <Typography paddingBottom={2}>
                Eメールソフトウェアの利便性に関する調査に協力して頂き、ありがとうございました。
            </Typography>

            <Typography paddingBottom={2}>
                表示されたEメールのうち、いくつかは<b>フィッシング攻撃</b>に用いられるEメールであることに気づきましたでしょうか。
		パスワードや個人情報を盗む目的で行われるこの攻撃ですが、多くの場合、Eメールに添付されている不正なリンクをクリックすることが発端になっています。
            </Typography>

            <Typography paddingBottom={6}>
		本調査ではEメールに含まれるリンクをクリックした際、すぐに外部のウェブサイトを表示するのではなく、皆さまをまずセキュリティページに誘導しました。
		その際、安全性を担保する手法をいくつか導入し、どの手法がどれだけ有効かを測定する目的で調査を行いました。
		この調査に参加して頂いたことで、インターネットを使用する全ユーザの安全を守るために必要な技術の開発にご協力頂いたことになります。
            </Typography>

            <Typography paddingBottom={2}>
                <b>次のページに進み、短いアンケートに回答して頂くことで参加報酬を得ることができます。</b>
            </Typography>

            <Box py={2}></Box>
            <Button variant="contained" className="Button" onClick={handleClickConfirm}>
                次へ進む
            </Button>

        </Container>
    );
}
