import { Card, Avatar, CardContent, Typography, CardMedia, Divider } from '@mui/material'
import Carousel from 'react-material-ui-carousel'


export default function EmployeeBadge({ employee, company, pictureSelector = false, pictureSelectorHandler = null, ...props }) {

    const pfpSize = "75%"

    // TODO move out
    const carouselItems = [
        "avatar1.png",
        "avatar2.png",
        "avatar3.png",
        "avatar4.png",
        "avatar5.png",
        "avatar6.png",
        "avatar7.png",
        "avatar8.png",
        "avatar9.png",
        "avatar10.png",
        "avatar11.png",
    ];

    const handlePictureChange = (current, old) => {
        if (pictureSelectorHandler) {
            pictureSelectorHandler(carouselItems[current]);
        }
    };


    return (
        <Card sx={{ textAlign: "center" }} raised={true}>

            {pictureSelector &&

                <Carousel
                    autoPlay={false}
                    animation='slide'
                    navButtonsAlwaysVisible={true}
                    indicators={false}
                    onChange={handlePictureChange}
                    index={employee.picture ? carouselItems.indexOf(employee.picture) : 0}
                >
                    {
                        carouselItems.map((item, i) =>
                            <Avatar
                                sx={{ height: pfpSize, width: pfpSize, aspectRatio: 1, margin: "10px auto" }}
                                src={`images/avatars/${item}`}
                                title={employee.name}
                                key={`carousel_avatar_${i}`}
                            />
                        )
                    }
                </Carousel>
            }

            {!pictureSelector &&
                <Avatar
                    sx={{ height: pfpSize, width: pfpSize, aspectRatio: 1, margin: "10px auto" }}
                    src={`images/avatars/${employee.picture}`}
                    title={employee.name}
                />
            }


            <CardContent>
                <Typography gutterBottom variant="h5" component="div" color="primary">
                    {employee.surname} {employee.name}
                </Typography>
                <Typography gutterBottom color="text.secondary" component={"div"} sx={{ marginBottom: "2rem" }}>
                    {employee.responsibilities[0]}・{employee.responsibilities[1]}
                </Typography>

                <Divider variant='middle' role="presentation">
                    <Typography gutterBottom variant="h5" component="div">
                        {company.name}
                    </Typography>
                </Divider>
            </CardContent>
            <CardMedia
                component="img"
                image={company.logo}
                sx={{ width: "250px", margin: "10px auto" }}
            />
        </Card>
    )

}
