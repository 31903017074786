import { Box, Typography } from "@mui/material"
import { MailOutline } from "@mui/icons-material"


export default function SelectEmail() {

    return (
        <Box textAlign={"center"} paddingY={8}>
            <MailOutline color="primary" sx={{fontSize: "7rem"}} />
            <Typography>
                Eメールを選択してください
            </Typography>
        </Box>
    )
}
