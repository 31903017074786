import { Box, Typography, Stack } from "@mui/material";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { URLBlock } from "../elements/URLBlock";
import { URLDrop } from "../elements/URLDrop";
import Dot from "../elements/Dot";
import { useState, useMemo } from "react";

export default function RealignDomain({ challenge, handler, ...props }) {

    let urlIds = useMemo(() => [], []);
    let urlStates = useMemo(() => {
        let _urlStates = {};
        for (let i = 0; i < challenge.propositions.length; i++) {
            const blockId = `url-block-${i}`;
            urlIds.push(blockId);
            _urlStates[blockId] = {
                id: blockId,
                fragment: challenge.propositions[i],
                placement: Math.random() > 0.5 ? 'top' : 'bottom',
                displacement: `${Math.random() * 2 + 0.5}rem`,
                highlight: i >= (challenge.propositions.length - 2),
                completed: false,
            };
        }
        return _urlStates;
    }, [challenge.propositions]);

    const [completed, setCompleted] = useState(false);

    const dragEndHandler = ({over}) => {
        if (over) {
            urlStates[over.id].completed = true;
            let _completed = true;
            for (let urlId of urlIds) {
                if (!urlStates[urlId].completed) {
                    _completed = false;
                    break;
                }
            }
            if (_completed) {
                setCompleted(true);
                handler(challenge.solution);
            }
        }
    }

    const colors = ["#BD376A", "#B84600", "#8E6200", "#006FB3", "#4867B8", "#6C55D8"];
    colors.sort(() => (Math.random() - 0.5));





    return (
        <Box>
            <DndContext 
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={dragEndHandler}
            >

            <Typography paddingBottom={3}>アドレスを<b>ドラッグ・アンド・ドロップ</b>し、アクセスを試みたウェブサイトのリンクを完成させてください。</Typography>
            <br />

            <Stack spacing={2} className="attention-tutorial-challenge">

                <Box sx={{height: "4rem", width: "100%"}}>
                    <Stack direction="row" spacing={1} sx={{height: "100%"}} divider={<Dot color='white' />} justifyContent="center">
                        {urlIds.map((urlId, index) => {
                            return <Box key={urlId} sx={{paddingBottom: urlStates[urlId].displacement}}><URLBlock id={urlId+"a"} urlState={urlStates[urlId]} placement="top" color={colors[index]}>{challenge.propositions[index]}</URLBlock></Box>
                        })}
                    </Stack>
                </Box>

                <Box sx={{width: "100%"}}>
                    <Stack direction="row" spacing={1} sx={{height: "100%"}} divider={<Dot />} justifyContent="center">
                        {urlIds.map((urlId, index) => {
                            return <Box key={urlId}>
                                <URLDrop id={urlId} urlState={urlStates[urlId]} isDomain={index > urlIds.length - 3} color={colors[index]}>
                                    {challenge.propositions[index]}
                                </URLDrop></Box>
                        })}
                    </Stack>
                </Box>

                <Box sx={{height: "4rem", width: "100%"}}>
                    <Stack direction="row" spacing={1} sx={{height: "100%"}} divider={<Dot color='white' />} justifyContent="center">
                        {urlIds.map((urlId, index) => {
                            return <Box key={urlId} sx={{paddingTop: urlStates[urlId].displacement}}><URLBlock id={urlId+"b"} urlState={urlStates[urlId]} placement="bottom" color={colors[index]}>{challenge.propositions[index]}</URLBlock></Box>
                        })}
                    </Stack>
                </Box>

            </Stack>
            <br />
            </DndContext>
            
            {completed && 
            <Typography>本当に次のウェブサイトにアクセスしますか？</Typography>
            }

        </Box>

    );

}
