import { useState } from 'react';
import { Button, Box, Typography, Container, Grid, Item } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { BACKEND_ADDRESS } from '../App';
import QuestionnaireRadio from '../components/framework/Questionnaire/QuestionnaireRadio';
import Likert from '../components/framework/Questionnaire/QuestionnaireLikert';
import QuestionnaireGender from '../components/framework/Questionnaire/QuestionnaireGender';
import { useLocalStorage } from "@uidotdev/usehooks";
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import { Title } from '../components/base/typography';
import { Pages } from '../Types';


let known_services = {
    paypal: 0,
    stripe: 0,
    facebook: 0,
    instagram: 0,
    tiktok: 0,
    twitter: 0,
    ups: 0,
    dhl: 0,
    googleaccount: 0,
    icloud: 0
}

export default function PreQ() {

    const [formValues, setFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleClickConfirm = (event) => {

        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'onboarding',
                    'form_data': { ...formValues, known_services }
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.BACKGROUND });
            })
            .catch((err) => console.log(err));
    };

    const handleFormValueChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckboxChange = (event) => {

        if (known_services[event.target.value] == 1) {
            known_services[event.target.value] = 0;
        } else {
            known_services[event.target.value] = 1;
        }
    };

    return (
        <Container>
            <Title>
                ようこそ！
            </Title>

            <Typography>
                調査にご協力頂き、ありがとうございます。<br />
                まず、以下の質問にお答えください。
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>

                <QuestionnaireGender />

                <QuestionnaireRadio
                    label="年齢"
                    values={[
                        { label: "18-20", value: "18-20" },
                        { label: "21-30", value: "21-30" },
                        { label: "31-40", value: "31-40" },
                        { label: "41-50", value: "41-50" },
                        { label: "51-60", value: "51-60" },
                        { label: "61+", value: "61+" }
                    ]}
                    name="age" />

                <QuestionnaireRadio
                    label="最終学歴"
                    values={[
                        { label: "高校", value: "High school" },
                        { label: "学士", value: "Bsc." },
                        { label: "修士", value: "Msc." },
                        { label: "博士", value: "PhD" }
                    ]}
                    name="education" />

                <QuestionnaireRadio
                    label="私はプレイベートEメールアドレスを所持している。"
                    values={[
                        { label: "はい", value: "yes" },
                        { label: "いいえ", value: "no" }
                    ]}
                    noAnswer={false}
                    name="personal_email" />

                <QuestionnaireRadio
                    label="私は業務用Eメールアドレスを所持している。"
                    values={[
                        { label: "はい", value: "yes" },
                        { label: "いいえ", value: "no" }
                    ]}
                    noAnswer={false}
                    name="work_email" />
            </QuestionnaireGroup>
            
            <br />
            <Typography>
                以下の質問には1から5までの数字をご入力ください。1は「まれに」もしくは「まったく」、5は「常に」もしくは「かならず」に相当します。<br />
                <b>一日のうち、以下のデバイスやソフトウェアをどの程度使用しますか？</b>
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>
                <Likert
                    label="デスクトップ、またはノートパソコン"
                    name="computer_use" />

                <Likert
                    label="スマートフォン"
                    name="smartphone_use" />

                <Likert
                    label="Eメール"
                    name="email_use" />

                <Likert
                    label="インスタント・メッセンジャーアプリ（例：SMS、LINE、iMessage、等）"
                    name="messaging_use" />

                <Likert
                    label="その他メッセージアプリ（例：Teams、Slack、Zoom、Webex等）"
                    name="job_communication_use" />

                <Likert
                    label="非技術的な目的でのパソコンの使用（例：Microsoft Office（ワード、エクセルなど）、データ入力等）"
                    name="job_computer_use" />

                <Likert
                    label="技術的な目的でのパソコンの使用（例：プログラミング等）"
                    name="job_technical_computer_use" />


            </QuestionnaireGroup>
            
            <br />
            <Typography>
                最後に、<b>サイバーセキュリティ</b>についてお伺いします。
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>
                <QuestionnaireRadio
                    label="Eメールを用いた詐欺について知っている。"
                    values={[
                        { label: "1 （全く知らない）", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5 （良く知っている）", value: "5" }
                    ]}
                    noAnswer={true}
                    name="scam_familiarity" />

                <QuestionnaireRadio
                    label="「フィッシング」という単語を知っている。"
                    values={[
                        { label: "1 （全く知らない）", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5 （良く知っている）", value: "5" }
                    ]}
                    noAnswer={true}
                    name="phishing_familiarity" />

                <QuestionnaireRadio
                    label="過去6ヶ月の間、個人用Eメールアドレスに届いた詐欺メールの数は？"
                    values={[
                        { label: "一つも届かなかった", value: "no" },
                        { label: "一つ届いた", value: "one" },
                        { label: "一つ以上届いた", value: "more than one" },
                        { label: "知らない（記憶にない）", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="received_phishing_personal" />

                <QuestionnaireRadio
                    label="過去一年の中で、フィッシングEメールを用いた詐欺に引っかかったことがある。"
                    values={[
                        { label: "全くない", value: "no" },
                        { label: "あと一歩で引っかかるところだった", value: "almost" },
                        { label: "引っかかった", value: "yes" },
                        { label: "知らない（記憶にない）", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="fell_for_scam" />

                <QuestionnaireRadio
                    label="過去3ヶ月の間、業務用Eメールアドレスに届いた詐欺メールの数は？"
                    values={[
                        { label: "一つも届かなかった", value: "no" },
                        { label: "一つ届いた", value: "one" },
                        { label: "一つ以上届いた", value: "more than one" },
                        { label: "知らない（記憶にない）", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="received_phishing_work" />

                <QuestionnaireRadio
                    label="職場Eメールセキュリティの研修を受けた回数は？"
                    values={[
                        { label: "一度もない", value: "no" },
                        { label: "まれに（もしくは一度だけ）", value: "sporadically" },
                        { label: "頻繁に受ける", value: "yes" },
                        { label: "職場ではEメールを使用しない", value: "don't use emails" }
                    ]}
                    noAnswer={true}
                    name="job_training" />
            </QuestionnaireGroup>
            
            <br />
            <Typography>
                <b>以下のウェブサービスのうち、使用したことのあるものを選択してください（複数選択可）。</b>
            </Typography>

            <FormLabel id="familiar-services-checkbox" sx={{ paddingTop: '20px', lineHeight: '2.4rem' }}>どのウェブサービスを使用したことがありますか？</FormLabel>
            <FormGroup onChange={handleCheckboxChange} aria-labelledby="familiar-services-checkbox" name="known_services" row>
                <FormControlLabel control={<Checkbox />} label="Facebook" value="facebook" />
                <FormControlLabel control={<Checkbox />} label="Instagram" value="instagram" />
                <FormControlLabel control={<Checkbox />} label="X (Twitter)" value="twitter" />
                <FormControlLabel control={<Checkbox />} label="UPS" value="ups" />
                <FormControlLabel control={<Checkbox />} label="DHL" value="dhl" />
                <FormControlLabel control={<Checkbox />} label="FedEx" value="fedex" />
                <FormControlLabel control={<Checkbox />} label="Google Drive" value="googledrive" />
                <FormControlLabel control={<Checkbox />} label="Onedrive / Sharepoint" value="onedrive" />
                <FormControlLabel control={<Checkbox />} label="Microsoft Teams" value="teams" />
                <FormControlLabel control={<Checkbox />} label="Slack" value="slack" />
                <FormControlLabel control={<Checkbox />} label="PayPal" value="paypal" />
                <FormControlLabel control={<Checkbox />} label="Stripe" value="stripe" />
            </FormGroup>
            <Box py={2}></Box>

            <Box py={2}></Box>
            <Button variant="contained" className="Button" onClick={handleClickConfirm}>
                次へ進む
            </Button>
            <p>&nbsp;</p>


        </Container>
    );
}
