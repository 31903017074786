import { Typography, Container, List, ListItem, Paper } from '@mui/material'
import { Subtitle, Title } from '../components/base/typography';
import { useLocalStorage } from '@uidotdev/usehooks';
import { BACKEND_ADDRESS } from '../App';

import Consent from '../components/framework/Consent';
import { baseExperimentStatus, Pages } from '../Types';

export default function ConsentShort () {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    const handleClickConfirm = (event) => {

        fetch(BACKEND_ADDRESS + "/users", { method: 'POST', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({
            "id": participant.platformId ? participant.platformId : "NOID",
        }) })
            .then(async (res) => {
                let res_json = await res.json();
                setParticipant(res_json);
                setExperiment({ ...experiment, page: Pages.PRE_QUESTIONNAIRE })
            })
            .catch((err) => console.log(err));

    };

    return (
        <Container>
            <Title>
                Eメールソフトウェア 利便性 調査
            </Title>

            <Typography pb={2}>
                私たちはスイスのチューリッヒ工科大学（以下、<a href='https://ethz.ch'>ETH Zurich</a>）でEメールソフトウェアの利便性や安全性について研究しているグループです。
                今回、私たちが取り組んでいる新しい種類のEメールソフトウェアについて、機能面に関する調査を実施することになりました。
		調査に参加する前に、参加者である皆様の同意を事前に確認させていただきます。
            </Typography>

            <Typography pb={2}>
                この調査は次のようなロールプレイ方式で行われます：あなたは<b>とある企業の従業員</b>という想定のもと、会社で受信したメールを<b>一定時間内</b>で処理していきます。メールの処理は後ほどチュートリアルで紹介する操作方法に従っていただきます。
            </Typography>

            <Typography pb={6}>
                この調査ではアンケートへの回答に加え、タスクの処理速度などのデータを収集します。
                ただし、個人を特定できるような情報は収集しません。
                また、集計された統計値以外のデータは公開しません。
                <b>同意書の全文は<a href='./consent-full' target='_blank'>こちら</a>からご覧いただけます。</b>調査開始にあたり、同意書に記入をお願いしています。
            </Typography>

            <Typography pb={2}>
                この調査は、提案書EK-2023-N-204をもとにETH Zurich倫理委員会によって審査・承認されました。
            </Typography>
            <Typography pb={4}>
                調査に関してご不明な点がございましたらランサーズのメッセージ機能を利用しご連絡ください。
            </Typography>



            <Paper elevation={3} sx={{ padding: "1em 4em 2em", backgroundColor: "#F8F8F8" }}>
            <Subtitle>
                同意書
            </Subtitle>

            <Typography paddingBottom={2}>
                私（調査参加者）は、以下の項目を確認の上、同意します。
            </Typography>

            <List dense>
                <ListItem key={1}>
		    ・私は<a href='./consent-full' target='_blank'>同意書全文</a>を読み、内容を理解しました。
                </ListItem>
                <ListItem key={2}>
		    ・私は同意書全文に記載されている調査の参加および除外基準に従います。また、調査に求められる要件と制限事項を理解しました。
                </ListItem>
                <ListItem key={3}>
                    ・私は調査への参加を判断するのに十分な時間がありました。
                </ListItem>
                <ListItem key={4}>
		    ・私はこの調査への参加は任意であり、調査中に上記の方法でデータが収集、利用されることに同意します。
                </ListItem>
                <ListItem key={5}>
                    ・私はこの調査はいつでも中止できることを理解しました。
                </ListItem>
            </List>

            <Consent consentHandler={handleClickConfirm} />
            </Paper>
        </Container>

    );
}
