import { Box, Typography } from '@mui/material';
import { Title } from '../components/base/typography';

export default function Mobile() {
    return (
        <Box>
            <Title>Unsupported</Title>
            <Typography>
                このウェブサイトはモバイルデバイスに対応しておりません。デスクトップもしくはラップトップパソコンをご使用ください。
            </Typography>
        </Box>
    )
}
