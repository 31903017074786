import { Container, Typography } from "@mui/material";
import { Subtitle, Title } from "../components/base/typography";

export default function ConsentFull() {

    return (
        <Container>
            <Title>
		同意書全文 - Eメールソフトウェア 利便性 調査
            </Title>

            <Typography>
		私たちはスイスのチューリッヒ工科大学（以下、<a href='https://ethz.ch'>ETH Zurich</a>）でEメールソフトウェアの利便性や安全性についてを研究しているグループです。
            </Typography>
            <Typography pb={2}>
		この調査に参加する前に、参加者である皆様の同意を事前に確認しております。この調査への参加は任意です。以下の注意事項をご確認ください。
            </Typography>

            <Subtitle>
                何が調査され、どのように行われるのか？
            </Subtitle>
            <Typography>
                私たちは、Eメールの使いやすさを向上させる新しいインターフェースを設計しています。
                この調査は次のようなロールプレイ方式で行われます：あなたはとあるの企業の従業員という想定のもと、その従業員が受け取ったEメールを一定時間内に処理していきます。
            </Typography>

            <Subtitle>
                誰が参加できるのか？
            </Subtitle>
            <Typography>
                この調査には、個人用または業務用のEメールアドレスを所有し、Eメールを定期的に使用している18歳以上のインターネットユーザーが参加できます。
                調査は日本語で行われるため標準的な日本語能力が求められます。
                また、参加されるユーザは調査で使用されるプラットフォーム上で少なくとも95％のタスク承認率を保持している必要があります。
            </Typography>

            <Subtitle>
                参加者として何をするのか？
            </Subtitle>
            <Typography>
                参加者には最初に、Eメールの使用習慣に関するアンケートに答えていただきます。
                回答終了後、短いチュートリアルが表示され、ロールプレイングを用いた調査が開始されます。
                この調査では架空の企業の従業員のメールボックスが表示されます。与えられた指示に従い、できる限り多くのEメールを制限時間内に処理することが求められます。
                Eメールの処理が終わった後、Eメールのユーザーインターフェースに関する2回目のアンケートに答えていただきます。
            </Typography>

            <Subtitle>
                参加中の権利は？
            </Subtitle>
            <Typography>
                この調査への参加は任意です。
                調査ページ上部のリンクをクリックすることで、いつでも参加を中止できます。
                この際、参加中止に関する理由を明示する必要ありません。また、参加を中止したことにより参加者への不利益は発生しません。
            </Typography>

            <Subtitle>
                どのようなリスクが発生するのか？
            </Subtitle>
            <Typography>
                調査では、参加者の個人情報は収集されません。
                調査で収集されるデータはEメールソフトウェアの利便性の向上のみに使用されます。
                また、参加者は保護された環境内の模擬Eメールソフトウェアを使用します。
                そのため調査中にクリックする可能性のあるリンクによって危険なコンテンツに誘導されることは一切ありませんのでご安心ください。
            </Typography>

            <Subtitle>
                参加の報酬は？
            </Subtitle>
                参加の対価は調査で使用しているプラットフォームを通じて支払われます。
                調査の終了時、または参加を中止する場合、参加確認コードが発行されます。このコードをプラットフォームのウェブサイトに入力することで報酬を受け取ることができます。
                参加確認コードの発行は一度のみですのでご注意ください。
            <Typography>

            </Typography>

            <Subtitle>
                どのようなデータが収集され、どのように使用されるのか？
            </Subtitle>
            <Typography>
                調査中に個人を特定できる情報（Personally Identifiable Information）は収集されません。
                一部のアンケート項目には、性別や年齢範囲といったセンシティブな質問があります。これらを含めすべてのアンケート項目への回答は任意であり、回答しないことも選択できます。
            </Typography>
            <Typography>
                調査では、シミュレートされたEメールボックスとEメールとのやり取りをもとに以下のデータが収集されます：
                <ul>
                    <li>タスクのパフォーマンス（処理されたEメールの数、処理速度、および処理方法）</li>
                    <li>タスクごとに費やした時間：各Eメールの読み取りおよび処理にかかった時間、それぞれのページに費やした時間、および他のインターネット活動と比較して等調査に費やした時間など。</li>
                </ul>
            </Typography>
            <Typography>
                収集されたすべてのデータはETH Zurichのシステムセキュリティグループのサーバーに匿名化された状態で安全に保管されます。
                収集されたデータは公開されません。今回の調査に基づいた論文においてはデータを処理して得られた統計値のみを公開します。
                収集されたデータから個々の参加者に関する情報を得ることはできませんのでご安心ください。
            </Typography>
            <Typography>
                ETH Zurich倫理委員会のメンバーが検査のために収集されたデータにアクセスすることがあります。その際は厳重な機密保持の下でアクセスが行われます。
            </Typography>

            <Subtitle>
                参加者の個人データに関する権利は？
            </Subtitle>
            <Typography>
                収集されたデータが匿名化される前に、収集されたデータに関する情報をいつでも理由を示さずに要求できます。
                また、データの修正、引き渡し、処理の停止、または削除を要求することもできます。その際は、上記の担当者に連絡してください。
            </Typography>

            <Subtitle>
                この調査は誰によって審査されたのか？
            </Subtitle>
            <Typography>
                この調査は、提案書EK-2023-N-204をもとにETH Zurich倫理委員会によって審査・承認されました。
            </Typography>

            <Subtitle>
                相談受付窓口
            </Subtitle>
            <Typography>
                調査に関してご不明な点がございましたらランサーズのメッセージ機能を利用しご連絡ください。
            </Typography>
        </Container>
    );
}
