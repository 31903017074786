import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"

export default function QuestionnaireLikert ({label, name, onChange, least='まれに', most='常に', scale=5, ...props}) {

  const handleChange = onChange ? onChange : () => undefined;
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'bottom';


  return (
    <div className='questionnaire-row'>
      <FormLabel id={`${name}-radio-button`} className="questionnaire-label">{label}</FormLabel>
      <RadioGroup onChange={handleChange} row aria-labelledby={`${name}-radio-button`} name={name}>
        <FormControlLabel labelPlacement={labelPlacement} value="1" control={<Radio />} label={`1 (${least})`} />
        {Array.from({length: scale-2}, (_, i) => i+2).map((val) => (
          <FormControlLabel key={val} labelPlacement={labelPlacement} value={val.toString()} control={<Radio />} label={val} />
        ))}
        <FormControlLabel labelPlacement={labelPlacement} value="5" control={<Radio />} label={`5 (${most})`} />
        <FormControlLabel labelPlacement={labelPlacement} value="no answer" control={<Radio />} label="回答しない" />
      </RadioGroup>
    </div>
  );
};
