import { Button, Grid, Box, Typography, Container, List, ListItem, ListItemText, Drawer, Paper, IconButton, Collapse } from '@mui/material';
import EmailViewer from '../components/mailbox/EmailViewer';
import Timer from '../components/framework/Timer';
import { useState, useEffect, useMemo } from 'react';
import { BACKEND_ADDRESS } from '../App';
import { ChallengeTypes, ExperimentProgress, baseExperimentStatus, MechanismStatus, Pages, FailureTypes, TutorialStatus } from '../Types';
import { logEvent } from '../networking';
import ProfilePicture from '../components/persona/ProfilePicture';
import MailboxButton from '../components/mailbox/MailboxButton';
import {Phishing, MarkEmailUnread, Archive, Help, NoEncryption, Title, ChevronRight, ChevronLeft, CheckBox, Work} from '@mui/icons-material';
import EmailsList from '../components/mailbox/EmailsList';
import MailboxButtons from '../components/mailbox/MailboxButtons'
import SelectEmail from '../components/mailbox/SelectEmail';
import LoggerDialog from '../components/dialogs/LoggerDialog';
import { useLocalStorage } from '@uidotdev/usehooks';
import MailboxHeader from '../components/mailbox/MailboxHeader';
import { defaultTourOptions, nextButton, backButton, doneButton, studyProtocol, tutorialMailList } from './Tutorials';
import { Preformatted } from '../components/base/typography';
import TaskProgress from '../components/framework/TaskProgress';
import Shepherd from 'shepherd.js';
  



export default function MailClientTutorial() {

    const [participant, setParticipant] = useLocalStorage("participant", {});

    // const [mailList, setMailList] = useLocalStorage("emails", []);
    // const [mailCount, setMailCount] = useLocalStorage("mailCount", 0);
    // const [openEmail, setOpenEmail] = useLocalStorage("openEmail", '');

    const [mailList, setMailList] = useState(tutorialMailList);
    const [mailCount, setMailCount] = useState(tutorialMailList.length);
    const [openEmail, setOpenEmail] = useState(tutorialMailList[0]);

    const [leaveDialogOpen, setleaveDialogOpen] = useState(false);
    const [roleplayDialogOpen, setroleplayDialogOpen] = useState(false);
    const [handledDialogOpen, setHandledDialogOpen] = useState(false);
    const [reportedDialogOpen, setReportedDialogOpen] = useState(false);
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [protocolOpen, setProtocolOpen] = useState(true);
    const [showProtocol, setShowProtocol] = useState(false);
    const [postMechanism, setPostMechanism] = useState(false);

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});

    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);


    const tour = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.COMPLETED_FIRST });
            console.log("Tutorial 1 completed");
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            {
                text: `これからチュートリアルを開始します。Eメールソフトウェアの操作方法やEメールの処理方法を見ていきましょう。`,
                buttons: [ nextButton ],
            },
            {
              text: `こちらが本日初めて受け取ったEメールです。
              <b>こちらのEメールにはリンクが含まれておりません</b>。
	      このようなEメールを処理する場合は、内容を大まかに読み、そして上部の「完了する」ボタンをクリックします。`,
              attachTo: { element: '.mailbox-viewer-container', on: 'left' },
              buttons: [ nextButton ],
            },
            {
                text: `こちらが本日受け取った二件目のEメールです。
		<b>このEメールにはリンクが含まれています</b>。
		このようなEメールを処理する場合は、まずリンクをクリックします。
		この例では、フェイスブックページのダッシュボードを確認するためにリンクをクリックするという形になります。
                <br /><br />
		繰り返しになりますが、リンクをクリックすることで<b>外部へのサイトへ移動することはありません</b>。
		あくまでも、あなたのキャラクターがウェブサイトへ移動したという想定になります。
                `,
                attachTo: { element: '.mailbox-viewer-container', on: 'left' },
                buttons: [ {
                    text: "次へ",
                    action() {
                      return this.complete();
                    },
                  } ],
                when: { 
                    "before-show": () => { 
                        handleListClick(null, mailList[1]);
                    }
                },
            },
        ];
        tour.addSteps(mailclientSteps);
        return tour;
    }, [experiment.page]);

    const tour2 = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.COMPLETED, page: Pages.MAILBOX });
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            { 
                text: `ときおり<b>迷惑メール</b>や詐欺に用いられるEメールが届くことがあります。`,
                buttons: [ nextButton ],
                when : { 
                    "before-show": () => {
                        handleListClick(null, mailList[2]);
                    }
                }
            },
            {
                text: `こちらは三件目のEメールです。パスワードを要求していることから、<b>詐欺のEメール</b>であることが見て取れます。
                <br /> 
                このようなEメールを見かけた場合は、「報告する」ボタンをクリックして情報システム部署へEメールを報告し、処理しましょう。`,
                attachTo: { element: (() => '.mailbox-viewer-container'), on: 'left' },
                buttons: [ nextButton ],
            },
            {
                text: `これでチュートリアルは終了です。これから様々な種類のEメールを、できるだけ多く処理していただきます。制限時間は15分です。
                <br /><br />
		もしご不明な点がありましたら右のタブをクリックして頂くことでこのチュートリアルでご説明した指示をいつでも確認できます。また、キャラクターの画像をクリックすることでキャラクターの情報を再確認できます。`,
                attachTo: { element: '.mailbox-protocol-container', on: 'left' },
                buttons: [ doneButton ],
                when: {
                    "before-show": () => {
                        setShowProtocol(true);
                    },
                }

            }
        ];
        tour.addSteps(mailclientSteps);
        return tour;
    }, [experiment.page]);

    let listeners = [];

    useEffect(() => {
        document.getElementById("mailbox-viewer").scrollTo(0, 0);
      }, [openEmail]);

    const roleplayDescription = <></>

    const handleListClick = (event, email) => {
        setMailList(mailList.map((mail) => {
            if (mail._id === email._id) {
                mail.read = true;
                mail.selected = true;
            }
            else {
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(email);
    };

    function handleProcessedEmail() {}

    const handleLeave = () => {};

    const handleTimeout = () => {};

    const handleMarkUnread = () => {};

    const handleArchive = () => {};

    const handleSpamReport = () => {};

    const handleHandledEmailConfirm = () => {};

    const handleSpamReportConfirm = () => {};

    const handleRoleplayDialog = () => {};

    const displayTutorial = () => {};

    const displayProtocol = () => {};

    const showTour = () => {}

    // Tutorial states
    // 1. Tutorial not started (coming from BG) - start it
    if (experiment.tutorialStatus === TutorialStatus.NOT_STARTED) {
        logEvent(participant._id, "tutorial_start", {});
        handleListClick(null, mailList[0]);
        setExperiment({ ...experiment, tutorialStatus: TutorialStatus.STARTED_FIRST });
        tour.start();
    }
    // safeguard - if tutorial got closed we reopen it
    else if (experiment.tutorialStatus === TutorialStatus.STARTED_FIRST) {
        if (!tour.isActive()) {
            tour.start();
        }
    }   
    // 2. Done with first part - decide whether to show attention tutorial or not; set second tutorial to start
    else if (experiment.tutorialStatus === TutorialStatus.COMPLETED_FIRST) {
        if (participant.group !== "control") {
            let challengeId = null;
            let failureId = null;
            if (participant.group === "baseline") {
                challengeId = ChallengeTypes.PASSIVE_CONFIRM;
            }
            else if (participant.group === "reorder") {
                challengeId = ChallengeTypes.REALIGN_DOMAIN;
            }
            else {
                challengeId = ChallengeTypes.SELECT_DOMAIN;
                failureId = FailureTypes.SHOW;
            }
            setExperiment({ ...experiment, clickedUrl: mailList[1].url.url, challengeId: challengeId, failureId: failureId, page: Pages.ATTENTION_DEMO, tutorialStatus: TutorialStatus.SHOULD_START_SECOND });
        }
        else {
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.SHOULD_START_SECOND });
        }
    }
    // 3. Either we're back from the mechanism or we're coming from the first tutorial - start the second tutorial
    else if (experiment.tutorialStatus === TutorialStatus.SHOULD_START_SECOND) {
        setExperiment({ ...experiment, mechanism: MechanismStatus.NOT_FROM_MECHANISM, tutorialStatus: TutorialStatus.STARTED_SECOND });

        setTimeout( tour2.start(), 30);
    }
    // safeguard - if tutorial got closed we reopen it
    else if (experiment.tutorialStatus === TutorialStatus.STARTED_SECOND) {
        if (!tour2.isActive()) {
            setTimeout( () => tour2.start(), 30);
        }
    }

    return (
        <Box className="mailbox-container">
            <Box className="mailbox-header">
                <MailboxHeader name={company.name} logo={company.logo}>

                    <Button onClick={() => handleRoleplayDialog()} color='inherit' className={"profile-picture"}>
                        <ProfilePicture employee={employee} company={company} />
                    </Button>

                </MailboxHeader>
            </Box>
            <Box className="mailbox-buttons">
                <MailboxButtons>
                    <MailboxButton image={<MarkEmailUnread fontSize='large' />} callback={handleMarkUnread} disabled={!openEmail}>
                        未読にする
                    </MailboxButton>
                    <MailboxButton image={<CheckBox fontSize='large' />} callback={handleArchive} disabled={!openEmail || openEmail.url !== null} className={"mailbox-archive"}>
                        完了する
                    </MailboxButton>
                    <MailboxButton image={<Phishing fontSize='large' />} callback={handleSpamReport} disabled={!openEmail} className={"mailbox-tutorial-5"}>
                        報告する
                    </MailboxButton>
                    <MailboxButton image={<Help fontSize='large' />} callback={displayProtocol} className={"mailbox-tutorial-7"}>
                        ヘルプ
                    </MailboxButton>
                </MailboxButtons>
            </Box>
            <Box className="mailbox-body">
                <Box className="mailbox-list-container">
                    <Typography variant='h5' sx={{ px: "1rem" }}>
                        インボックス
                    </Typography>
                    <Box className="mailbox-list mailbox-tutorial-1" >
                        <EmailsList emails={mailList} clickHandler={handleListClick} />
                    </Box>
                </Box>
                <Box className="mailbox-viewer-container">
                    <Box className="mailbox-viewer" id="mailbox-viewer">
                    {openEmail ?
                        <EmailViewer email={openEmail} listeners={listeners} /> :
                        <SelectEmail />
                    }
                    </Box>
                </Box>
                <Box className="mailbox-protocol-container">
                    <Paper className='mailbox-protocol' elevation={3} sx={{width: "100%", height: "100%"}}>

                        <IconButton onClick={() => setProtocolOpen(!protocolOpen)}>
                        {protocolOpen ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    <Collapse in={protocolOpen} orientation='horizontal'>
                        { showProtocol ? studyProtocol: <></>}
                    </Collapse>
                    </Paper>
                </Box>
            </Box>
        </Box>

    );
}
