import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import TargetURL from '../elements/TargetURL';
import LoggerDialog from '../../dialogs/LoggerDialog';
import { Preformatted } from '../../base/typography';



export default function SelectDomain({ challenge, handler, ...props }) {

    const handleChange = (event) => {
        let selectedText = window.getSelection().toString().replaceAll(/\n|\r/gi, '');
        handler(selectedText);
    }

    const [domainExplanationDialogOpen, setdomainExplanationDialogOpen] = useState(false);

    const domainDescription = <>
        <Typography>
            リンクの最後に表示されるウェブサイトを特定する部分を<emph>ドメイン</emph>と呼びます。
        </Typography>
        <Typography>
            例えば、<Preformatted>https://www.foo.example.com/page</Preformatted>の場合、ドメイン名は<Preformatted>example.com</Preformatted>となります。
        </Typography>
    </>

    return (
        <Box>

            <Typography variant='body1' paddingBottom={2}>あなたは次のリンクをクリックしました。</Typography>

            <Box paddingBottom={2} onMouseUp={handleChange} >
                <TargetURL url={challenge.url} />
            </Box>

            <Typography variant='body1'>アクセスを試みたリンクはどれですか？<br />リンクのうち、ドメイン名を<b>マウスでハイライト</b>してください。</Typography>
            <Typography paddingBottom={2} sx={{fontSize: "0.8rem"}}>(<Button variant='text' onClick={(event) => setdomainExplanationDialogOpen(true)}>ドメイン名とは？</Button>)</Typography>

            <LoggerDialog
                open={domainExplanationDialogOpen}
                onClose={() => setdomainExplanationDialogOpen(false)}
                title="ドメイン名とは？"
                description={domainDescription}
                eventName="domain_description_select"
                cancelText="Ok" />

        </Box>
    );

}
