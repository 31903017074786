import { Box, Typography } from "@mui/material";
import TargetURL from "../elements/TargetURL";
import PlainURL from "../elements/PlainURL";

export default function ShowFailure({answer, target, ...props}) {
    return (
        <Box>
            <Typography>あなたの回答は次のとおりです。</Typography>
            <PlainURL url={answer} />
            <br />

            <Typography>クリックしたリンクは別のウェブサイトへ移動しようとしているようです。</Typography>
            <PlainURL url={target.domainTLD} />
            <br />

            <Typography>このまま移動してもよろしいでしょうか？もしよろしければ「次へ進む」ボタンをクリックしてください。もし再度Eメールの確認または情報システム部署へ報告を行う場合は「前へ戻る」ボタンをクリックしてください。</Typography>
        </Box>
    )
}
